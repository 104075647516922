<template>
  <div class="study">
     <div class="box">
          <div class="title">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{name: 'onlineStudy'}">在线学习</el-breadcrumb-item>
                <el-breadcrumb-item :to="{name: 'onlineStudy', params: {type: 2}}">科普视频</el-breadcrumb-item>
                <el-breadcrumb-item>{{lesson.groupName}}</el-breadcrumb-item>
              </el-breadcrumb>
              <div class="tool">{{lesson.name}}</div>
          </div>
          <div class="content">
            <video controls :src="lesson.videoUrl" :poster="lesson.videoPoster"></video>
          </div>
      </div>
  </div>
</template>
<script>
import {$lesson} from '@/services'
import {ROOT} from '@/services/base'
export default {
  name: 'Video',
  data() {
    return {
      lesson: {}
    }
  },
  created() {
    this.$nextTick(() => {
        document.documentElement.scrollTop = 70
    })
    let id = this.$route.query.id;
    $lesson.getMenuList().then(result => {
      if (result.code == 0) {
        $lesson.getLesson(id).then(res => {
          if (res.code == 0) {
            let vdo = res.data
            this.lesson = {
              ...res.data,
              videoUrl: `${ROOT}/${vdo.kecheng}`,
              videoPoster: `${ROOT}/${vdo.kc_img}`,
              groupName: result.data.find(menu => menu.id == res.data.group).name
            }
          }
        })
      }
    })
  }
}
</script>
<style lang="less" scoped>
@import url('@/styles/study.less');
video {
  width: 100%;
  max-width: 100%;
}
.el-breadcrumb {
  color: #fff;
  /deep/ &__inner {
    color: #fff !important;
  }
}
</style>